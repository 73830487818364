"use strict";

/*<!-- ============================================ -->
<!--             Load from the top                 -->
<!-- ============================================ -->*/

document.addEventListener("DOMContentLoaded", function () {
  document.body.style.overflowY = "scroll";
});
const backgroundGradient = getComputedStyle(
  document.documentElement
).getPropertyValue("--background-gradient");

const header = document.querySelector(".header");
const nav = document.querySelector(".nav");
const navLink = document.querySelectorAll(".nav__link");
const navLinks = document.querySelector(".nav__links");
const hamMenuBtn = document.querySelector(".header__main-ham-menu-cont");
const smallMenu = document.querySelector(".header__sm-menu");
const headerHamMenuBtn = document.querySelector(".header__main-ham-menu");
const headerHamMenuCloseBtn = document.querySelector(
  ".header__main-ham-menu-close"
);
const headerSmallMenuLinks = document.querySelectorAll(".header__sm-menu-link");

document.addEventListener("DOMContentLoaded", function () {
  document.body.style.overflowY = "scroll";

  navLinks.classList.add("hidden");

  setTimeout(() => {
    navLinks.classList.remove("hidden");
    navLinks.classList.remove("blurNav");
    navLink.forEach((link, index) => {
      link.style.animation = `fadeIn 1s ease-in-out forwards ${
        0.1 + index * 0.2
      }s`;
    });
  }, 1000);
});

/*<!-- ============================================ -->
<!--                    Logo link                  -->
<!-- ============================================ -->*/
const headerLogoConatiner = document.querySelector(".header__logo-container");

headerLogoConatiner.addEventListener("click", () => {
  location.href = "index.html";
});

/*<!-- ============================================ -->
<!--                    Hover Nav                  -->
<!-- ============================================ -->*/
const handleHover = function (e) {
  if (e.target.classList.contains("nav__link")) {
    const link = e.target;
    const sibilings = link.closest(".nav").querySelectorAll(".nav__link");

    sibilings.forEach((el) => {
      if (el !== link) el.style.opacity = this;
    });
  }
};
setTimeout(() => {
  const navLinks = document.querySelectorAll(".nav__link");
  navLinks.forEach((link) => {
    link.addEventListener("mouseover", handleHover.bind(0.5));
    link.addEventListener("mouseout", handleHover.bind(0.95));
  });
}, 600);

/*<!-- ============================================ -->
<!--          Contact Form Container               -->
<!-- ============================================ -->*/
document.addEventListener("DOMContentLoaded", function () {
  if (
    window.location.pathname === "/" ||
    window.location.pathname === "/contact.html"
  ) {
    const form = document.querySelector(".contact__form-container");

    if (form) {
      form.style.opacity = 0;

      setTimeout(() => {
        form.style.transition = "opacity 2s";

        form.style.opacity = 1;
      }, 1000);
    }
  }
});
/*<!-- ============================================ -->
<!--          Load home avatar and intro           -->
<!-- ============================================ -->*/
document.addEventListener("DOMContentLoaded", function () {
  if (
    window.location.pathname === "/" ||
    window.location.pathname === "/index.html"
  ) {
    const avatar = document.querySelector(".avatar");
    const tagline = document.querySelector(".tagline");
    const intro = document.querySelector(".intro");
    if (avatar && tagline && intro) {
      avatar.style.opacity = 0;
      tagline.style.opacity = 0;
      intro.style.opacity = 0;

      setTimeout(() => {
        avatar.style.transition = "opacity 2s";
        tagline.style.transition = "opacity 2s";
        intro.style.transition = "opacity 2s";
        avatar.style.opacity = 1;
        tagline.style.opacity = 1;
        intro.style.opacity = 1;
      }, 500);
    }
  }
});
/*<!-- ============================================ -->
<!--             Move btn and socials               -->
<!-- ============================================ -->*/
if (
  window.location.pathname.endsWith("index.html") ||
  window.location.pathname === "/"
) {
  window.addEventListener("resize", function () {
    try {
      const el = document.querySelectorAll(".movable");
      const target =
        window.innerWidth < 600
          ? document.querySelector(".nav")
          : document.querySelector(".nav_right");

      if (!target) {
        console.error("Target element not found.");
        return;
      }

      el.forEach(function (element) {
        if (element.parentNode) {
          element.parentNode.removeChild(element);
        }
        target.appendChild(element);
        element.style.visibility = "visible";
        element.style.opacity = 1;
        element.style.position = "relative";
      });
    } catch (err) {
      console.error(
        "Error occurred while moving elements at screen width: " +
          window.innerWidth,
        err
      );
    }
  });
  window.dispatchEvent(new Event("resize"));
}

/*<!-- ============================================ -->
<!--           Move Btn in Contact page            -->
<!-- ============================================ -->*/
document.addEventListener("DOMContentLoaded", function () {
  if (document.body.classList.contains("contact-page")) {
    const movables = document.querySelectorAll(".movable");
    movables.forEach((movable) => {
      movable.style.display = "none";
    });
  }
});

/*<!-- ============================================ -->
<!--                Hamburger Menu                 -->
<!-- ============================================ -->*/
hamMenuBtn.addEventListener("click", () => {
  smallMenu.classList.toggle("header__sm-menu--active");
  headerHamMenuBtn.classList.toggle("d-none");
  headerHamMenuCloseBtn.classList.toggle("d-none");
});
headerSmallMenuLinks.forEach((link) => {
  link.addEventListener("click", () => {
    smallMenu.classList.remove("header__sm-menu--active");
    headerHamMenuBtn.classList.remove("d-none");
    headerHamMenuCloseBtn.classList.add("d-none");
  });
});

/*<!-- ============================================ -->
<!--                Reveal Section                 -->
<!-- ============================================ -->*/
document.addEventListener("DOMContentLoaded", function () {
  setTimeout(function () {
    const allSections = document.querySelectorAll(".hide");
    const revealSection = function (entries, observer) {
      const [entry] = entries;

      if (!entry.isIntersecting) return;
      entry.target.classList.remove("section--hidden");
      observer.unobserve(entry.target);
    };

    const sectionObserver = new IntersectionObserver(revealSection, {
      root: null,
      threshold: 0,
    });
    allSections.forEach(function (section) {
      sectionObserver.observe(section);
      section.classList.add("section--hidden");
    });
  }, 1000);
});
/*<!-- ============================================ -->
<!--                 Particle JS                   -->
<!-- ============================================ -->*/
document.addEventListener("DOMContentLoaded", function () {
  if (typeof particlesJS !== "undefined") {
    particlesJS("particles-js", {
      particles: {
        number: {
          value: 100,
          density: {
            enable: true,
            value_area: 800,
          },
        },
        color: {
          value: "#FFC0CB",
        },
        shape: {
          type: "circle",
          stroke: {
            width: 0,
            color: "#FFC0CB",
          },
          polygon: {
            nb_sides: 5,
          },
        },
        opacity: {
          value: 0.5,
          random: false,
          anim: {
            enable: false,
            speed: 1,
            opacity_min: 0.1,
            sync: false,
          },
        },
        size: {
          value: 2,
          random: true,
          anim: {
            enable: false,
            speed: 40,
            size_min: 0.1,
            sync: false,
          },
        },
        line_linked: {
          enable: true,
          distance: 150,
          color: "#e0c5a3",
          opacity: 0.4,
          width: 1,
        },
        move: {
          enable: true,
          speed: 4,
          direction: "none",
          random: false,
          straight: false,
          out_mode: "out",
          bounce: false,
          attract: {
            enable: false,
            rotateX: 600,
            rotateY: 1200,
          },
        },
      },
      interactivity: {
        detect_on: "canvas",
        events: {
          onhover: {
            enable: false,
            mode: "repulse",
          },
          onclick: {
            enable: true,
            mode: "push",
          },
          resize: true,
        },
        modes: {
          grab: {
            distance: 400,
            line_linked: {
              opacity: 1,
            },
          },
          bubble: {
            distance: 400,
            size: 40,
            duration: 2,
            opacity: 8,
            speed: 3,
          },
          repulse: {
            distance: 200,
            duration: 0.4,
          },
          push: {
            particles_nb: 4,
          },
          remove: {
            particles_nb: 2,
          },
        },
      },
      retina_detect: true,
    });
  } else {
    console.error("particlesJS is not defined");
  }
  setTimeout(() => {
    document.body.style.animation = "gradient 15s ease infinite";
  }, 900);
});
/*<!-- ============================================ -->
<!--               Resize Nav Header               -->
<!-- ============================================ -->*/
document.addEventListener("DOMContentLoaded", function () {
  const navHeaderLinks = document.querySelectorAll(".nav-header .nav__link");

  navHeaderLinks.forEach((link) => {
    link.style.fontSize = "1.5rem";
    link.style.order = "3";
  });
});
/*<!-- ============================================ -->
<!--           About Page Hamburger Menu           -->
<!-- ============================================ -->*/

document.addEventListener("DOMContentLoaded", function () {
  if (window.location.pathname === "/about.html") {
    function handleAboutPageVisibility() {
      const currentWidth = document.documentElement.clientWidth;

      if (currentWidth < 1000) {
        document
          .querySelectorAll(".movable, .nav-header")
          .forEach((element) => {
            element.style.display = "none";
          });
        if (hamMenuBtn) hamMenuBtn.style.display = "block";
      } else {
        document
          .querySelectorAll(".movable, .nav-header")
          .forEach((element) => {
            element.style.display = "";
          });
        if (hamMenuBtn) hamMenuBtn.style.display = "none";
      }
    }

    function handleHamburgerMenu() {
      hamMenuBtn.addEventListener("click", () => {
        if (headerHamMenuBtn.classList.contains("d-none")) {
          headerHamMenuBtn.style.display = "none";
        } else {
          headerHamMenuBtn.style.display = "block";
        }

        if (headerHamMenuBtn.classList.contains("d-none")) {
          headerHamMenuCloseBtn.style.display = "block";
        } else {
          headerHamMenuCloseBtn.style.display = "none";
        }

        if (smallMenu.classList.contains("header__sm-menu--active")) {
          smallMenu.style.visibility = "visible";
          smallMenu.style.opacity = "1";
        } else {
          smallMenu.style.visibility = "hidden";
          smallMenu.style.opacity = "0";
        }
        headerHamMenuCloseBtn.classList.toggle("d-none");
      });

      headerSmallMenuLinks.forEach((link) => {
        link.addEventListener("click", () => {
          smallMenu.classList.remove("header__sm-menu--active");
          smallMenu.style.visibility = "hidden";
          smallMenu.style.opacity = "0";
          headerHamMenuBtn.classList.remove("d-none");
          headerHamMenuBtn.style.display = "none";

          headerHamMenuCloseBtn.classList.add("d-none");
          headerHamMenuCloseBtn.style.display = "block";
        });
      });
    }

    function debounce(func, wait) {
      let timeout;
      return function () {
        clearTimeout(timeout);
        timeout = setTimeout(() => func.apply(this, arguments), wait);
      };
    }

    handleAboutPageVisibility();
    handleHamburgerMenu();

    const debouncedHandleAboutPageVisibility = debounce(
      handleAboutPageVisibility,
      200
    );
    window.addEventListener("resize", debouncedHandleAboutPageVisibility);
  }
});
/*<!-- ============================================ -->
<!--              About Background                 -->
<!-- ============================================ -->*/ document.addEventListener(
  "DOMContentLoaded",
  () => {
    window.location.pathname === "/about.html"
      ? (header.style.background = backgroundGradient)
      : null;
  }
);
/*<!-- ============================================ -->
<!--            Projects Background                 -->
<!-- ============================================ -->*/ document.addEventListener(
  "DOMContentLoaded",
  () => {
    window.location.pathname === "/projects.html"
      ? (header.style.background = backgroundGradient)
      : null;
  }
);
